import React from 'react'

import InternalServerError from '../components/Error/InternalServerError'
import HomePage, { HomePageProps } from '../components/HomePage/HomePage'
import NYLandingPage from '../components/NYLandingPage/NYLanding'
import { logServerSidePropsError } from '../components/utilities/log-helper'
import { Region } from '../config/regions'
import { STATUS_CODE } from '../data/enums/statusCodes'
import fetchHomepageData from '../data/query/homepage/fetchHomepageData'
import fetchNYLandingData, { HomepageNYLandingData } from '../data/query/homepage/fetchNYLandingData'
import { SurrogateKeys } from '../types'
import { RouteRedMetrics } from '../utils/metrics'

export type CrunchAppProps = {
  NYLandingPageData?: HomepageNYLandingData
  homepageData: HomePageProps
  regionName?: string
  error?: boolean
}

export default ({ NYLandingPageData, homepageData, regionName, error }: CrunchAppProps) => {
  if (error) {
    return <InternalServerError />
  }

  if (NYLandingPageData) {
    return <NYLandingPage {...NYLandingPageData} />
  }

  return <HomePage {...homepageData} regionName={regionName!} />
}

export async function getServerSideProps(context) {
  const regionName = context?.req?.headers?.host?.split('.')?.[0]
  const redMetrics = new RouteRedMetrics({
    method: 'GET',
    route: 'homepage'
  }).start()

  const surrogateKeyHeader = context?.res?.getHeader('Surrogate-Key')?.split(' ')
  try {
    if (regionName === Region.NEW_YORK) {
      const NYLandingPageResponse = await fetchNYLandingData()

      if (
        NYLandingPageResponse.statusCode !== STATUS_CODE.success &&
        NYLandingPageResponse.statusCode !== STATUS_CODE.notFound
      ) {
        redMetrics.end({
          status: NYLandingPageResponse.statusCode,
          error: 'NY landing page error'
        })
        context.res.statusCode = NYLandingPageResponse.statusCode
        return {
          props: { error: true }
        }
      }

      if (NYLandingPageResponse.statusCode === STATUS_CODE.notFound || !NYLandingPageResponse.data) {
        redMetrics.end({
          status: STATUS_CODE.notFound,
          error: 'NY landing page data not found'
        })

        return { notFound: true }
      }

      redMetrics.end({
        status: STATUS_CODE.success
      })

      const surrogateKeys = [...surrogateKeyHeader, ...(NYLandingPageResponse.data.entryIDs as SurrogateKeys)]
        .filter(Boolean)
        .join(' ')
      context?.res?.setHeader('Surrogate-Key', surrogateKeys)
      delete NYLandingPageResponse.data.entryIDs

      return {
        props: {
          NYLandingPageData: NYLandingPageResponse.data
        }
      }
    }

    const homepageResponse = await fetchHomepageData(regionName)
    if (homepageResponse.statusCode !== STATUS_CODE.success && homepageResponse.statusCode !== STATUS_CODE.notFound) {
      redMetrics.end({
        status: homepageResponse.statusCode,
        error: 'Homepage data error'
      })
      context.res.statusCode = homepageResponse.statusCode
      return {
        props: { error: true }
      }
    }

    if (homepageResponse.statusCode === STATUS_CODE.notFound || !homepageResponse.data) {
      redMetrics.end({
        status: STATUS_CODE.notFound,
        error: 'Homepage data not found'
      })

      return { notFound: true }
    }

    redMetrics.end({
      status: STATUS_CODE.success
    })

    const surrogateKeys = [...surrogateKeyHeader, ...(homepageResponse.data.entryIDs as SurrogateKeys)]
      .filter(Boolean)
      .join(' ')
    context?.res?.setHeader('Surrogate-Key', surrogateKeys)

    delete homepageResponse.data.entryIDs

    return {
      props: {
        homepageData: homepageResponse.data,
        regionName
      }
    }
  } catch (error) {
    logServerSidePropsError(error)

    redMetrics.end({
      status: STATUS_CODE.internalServerError,
      error: 'Failed to load homepage data'
    })
    context.res.statusCode = STATUS_CODE.internalServerError
    return { props: { error: true } }
  }
}
